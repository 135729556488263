@import "../../../../scss/imports.scss";
.scrolling-carousel {
  margin: 0px 0px 0px 0px;
  position: relative;
  z-index: 1;
  .ticker {
    margin-top: 30px;
    overflow: visible !important;
    z-index: 1;
    .ticker__element {
      z-index: 1;
      &:hover {
        z-index: 2;
      }
    }
  }
  .container {
    display: flex;
    width: 100%;
    flex: 1;
  }
  .slide-item {
    display: flex;
    flex-grow: 1;
    margin: 0px 4px !important;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column;
  }
  .thumbnail {
    display: flex;
    flex-grow: 0;
    overflow: hidden;
    background-color: #272727;
    background-size: cover;
    background-position: center center;
    border-radius: 4px;
    &.Person,
    &.Entity {
      border-radius: 50%;
    }
    img {
      flex-shrink: 0;
      flex-grow: 1;
      margin-left: 10;
    }
  }

  .scroller {
    display: flex;
    margin: 10px 0px 0px 30px;
    padding-bottom: 20px;
    width: calc(100vw - 30px);
    overflow-x: scroll;
    overflow-y: unset;
    scrollbar-width: thin;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    position: relative;
    user-select: none;

    .items-container {
      display: flex;
      margin-left: -30px;
      width: min-content;
      min-width: min-content;
      flex: 1;
      flex-flow: row nowrap;
      .slide-item:first-child {
        margin-left: 30px;
      }
    }
    &::-webkit-scrollbar {
      background: transparent;
      height: 4px;
      margin-left: 30px;
    }

    &::-webkit-scrollbar-track {
      background: #161616;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #222222;
      border-radius: 2px;
      width: 40px;
      height: 2px;
    }
  }

  .logo {
    display: flex;
    margin: 20px 0px 10px 0px;
  }
  .title {
    font-family: MuliExtraBold;
    font-size: 18px;
    letter-spacing: 1px;
    color: white;
    margin: 20px 0px 10px 0px;
  }
  .view-all {
    margin-left: 30px;
    color: $radivision-blue;
    cursor: pointer;
    font-family: "Muli";
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    line-height: 30px;

    svg {
      height: 18px;
      margin-left: 4px;
      fill: $radivision-blue;
    }
  }
  .description {
    padding: 0px 10px 0px 0px;
    font-size: 14px;
    line-height: 22px;
    color: white;
    overflow: hidden;
    letter-spacing: normal;
    position: relative;
    margin-bottom: 0;
  }
  .badges {
    img {
      height: 56px;
    }
  }
  .item-title {
    display: flex;
    flex-grow: 1;
    padding: 0px 0px 0px 0px;
    margin-top: 10px;
    text-overflow: ellipsis;
    text-align: left;
    color: white;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 18px;
      min-height: 68px;
      max-height: 68px;
      overflow: hidden;
      position: relative;
      margin-bottom: 0;
    }
  }

  .header {
    margin: 0px 10px 0px 30px;
    flex-grow: 0;
    flex-flow: column;
    display: flex;
    flex-flow: row wrap;
    padding: 0px 35px;
    justify-content: flex-start;
    align-items: flex-end;
    .logo {
      max-width: 180px;
      max-height: 120px;
    }
    h6.description {
      margin-left: 30px;
      max-width: 724px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: normal;
      color: #aaa;
    }
  }

  .preview-image {
    flex-shrink: 0;
    flex-grow: 1;
    margin-left: 10px;
  }

  .image-placeholder {
    flex: 1;
    background-color: #aaa;
  }
  .item-card-container {
    position: relative;
    box-sizing: border-box;
    margin: 0px 4px;
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    border-radius: 4px;
    &.Person,
    &.Entity {
      border-radius: 50%;
    }
    img {
      flex-shrink: 0;
      flex-grow: 1;
      margin-left: 10;
    }
  }
  .item-card {
    color: #fff;
    font-size: 36px;
    border-radius: 4px;
    transition: transform 0.1s linear;
    position: relative;
    text-align: center;
    width: 100%;
    &.BookStory {
      .item-card-text {
        padding: 10px 0px;
        h3 {
          text-align: center;
        }
      }
    }
    &.PodcastStory,
    &.OriginalContentStory {
      .popover {
        .item-preview-container {
          padding-bottom: calc(100% * (9 / 16));
        }
      }
    }
    .popover {
      background-color: #212121;
      position: absolute;
      left: 0px;
      right: 0px;
      max-width: 480px;
      overflow: hidden;
      border-radius: 10px;
      transform-origin: top center;
      transition: all 0.2s cubic-bezier(0.5, 0.1, 0, 1);
      transition-delay: 0.6s;
      opacity: 0;
      &:hover {
        transform: scale(1.15) translateY(-20px);
        opacity: 1;
        .item-preview-container {
          .item-preview {
            z-index: 10;
          }
        }
      }
      .item-preview-container {
        position: relative;
        background-color: #272727;
        border-radius: 10px 10px 0px 0px;
        .item-video {
          border-radius: 10px 10px 0px 0px;
          transition: opacity 0.8s ease-in;
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
        }
        .item-preview {
          transition: opacity 0.8s ease-in;
          border-radius: 10px 10px 0px 0px;
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
        }
      }
      .item-card-actions {
        display: flex;
      }
      .item-card-text {
        padding: 10px 10px;
        position: relative;
        h3 {
          max-height: unset;
          font-size: 12px !important;
          min-height: unset;
          &::after {
            display: none;
          }
        }
      }
    }
    .item-card-text {
      font-size: 15px;
      color: #aaaaaa;
      text-align: left;
      padding: 15px 0px 10px 0px;
      transition: transform 0.1s linear;
      h3 {
        font-size: 14px;
        line-height: 20px;
        min-height: 40px;
        max-height: 40px;
        overflow: hidden;
        position: relative;
        margin-bottom: 0;
        padding: 0px;
        &::after {
          display: block;
          content: "";
          position: absolute;
          background-image: linear-gradient(90deg, rgba(22, 22, 22, 0), $dark-mode-bg);
          height: 15px;
          bottom: 0;
          right: 0;
          width: 70%;
        }
      }
    }
    .item-card-title {
      margin: 20px 0px;
      height: 40px;
      display: flex;
      justify-content: space-between;
    }
  }
  .Person,
  .Entity {
    .popover {
      .item-preview-container {
        border-radius: 50%;
      }
    }
  }
  .item-card-actions {
    padding: 13px 19px;
    // margin-top: 13px;
    display: none;
    align-items: center;
    width: 100%;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #525252;
      cursor: pointer;
      border-radius: 17px;
      flex: 1;
      margin: 0px 4px;
      padding: 7px;
      max-width: 60px;

      svg {
        height: 15px;
        width: 15px;
        fill: #fff;
      }

      &.play-btn {
        background-color: $radivision-blue;
      }

      &.saved {
        background-color: #fff;

        svg {
          fill: $radivision-blue;
        }
      }

      @include media-breakpoint-down(md) {
        padding: 7px;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        max-width: 32px;
        box-sizing: border-box;
      }
      @include media-breakpoint-down(sm) {
        border-radius: 50%;
        width: 36px;
        height: 36px;
        max-width: 36px;
        box-sizing: border-box;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 5px 5px;
      margin-top: 7px;
      box-sizing: border-box;
    }
  }
  .item-preview-container {
    border-radius: 4px;
    overflow: hidden;
    z-index: -1;
  }
  .item-preview {
    transition: opacity 0.8s ease-in;
    transition-delay: 1s;
    position: relative;
    z-index: -1;
  }
  .slick-current {
    .item-video {
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.5);
    }
  }
  .item-preview-container {
    padding-bottom: 100%;
    height: 0px;
    position: relative;
    .item-video {
      background-size: cover;
      background-position: center center;
      background-color: #171717;
      flex: 1;
      border-radius: 4px;
      overflow: hidden;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .item-preview {
      border-radius: 4px;
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
  .PodcastStory,
  .OriginalContentStory {
    .item-preview-container {
      padding-bottom: calc(100% * (9 / 16));
    }
    .item-preview {
    }
  }
  .Person,
  .Entity {
    .item-preview-container {
      border-radius: 50%;
    }
  }
  .next-button,
  .prev-button {
    position: absolute;
    width: 35px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
    height: 150px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    top: 100px;
    svg {
      fill: white;
      width: 48px;
      transition: all 0.3s;
      transition-timing-function: cubic-bezier(0.5, 0.1, 0, 1);
    }
    &:hover {
      svg {
        transform: scale(1.3);
        fill: #4cb4e7;
      }
    }
  }
  .next-button {
    right: 0px;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(22,22,22,0.5) 75%);
  }
  .prev-button {
    left: 0px;
    background: linear-gradient(90deg, rgba(22,22,22,0.5) 22%, rgba(0,0,0,0) 100%);
    
  }
  @include media-breakpoint-down(md) {
    .header {
      margin-left: 24px;
      padding: 0px 30px 0px 0px;
      h6.description {
        margin-left: 0px !important;
      }
    }
  }
  @media (min-width: 568px) {
    .prev-button,
    .next-button {
      height: 135px;
      top: 0px;
    }
  }
  @media (min-width: 768px) {
    .prev-button,
    .next-button {
      height: 135px;
      top: 0px;
    }
  }
  @media (min-width: 820px) {
    .prev-button,
    .next-button {
      height: 140px;
      top: 0px;
    }
  }
  @media (min-width: 1000px) {
    .prev-button,
    .next-button {
      height: 140px;
      top: 0px;
      width: 48px;
    }
  }
  @media (min-width: 1920px) {
    .prev-button,
    .next-button {
      height: 170px;
      width: 50px;
      top: 0px;
    }
  }
}
